<template>
    <div v-html="renderedMarkdown"></div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import markdownit from 'markdown-it';



@Component
export default class Doc extends Vue {
    @Prop({ type: String, required: true }) readonly markdownFile!: string;

    private renderedMarkdown: string = '';

    async mounted() {
        await this.loadMarkdown()
    }

    private async loadMarkdown() {
        try {
            const response = await fetch("/content/doc/main.md");
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const markdownContent = await response.text();
            this.convertMarkdown(markdownContent);
        } catch (error) {
            console.error('Error loading Markdown file:', error);
        }
    }

    private convertMarkdown(markdownContent: string) {
        const md = new markdownit();

        // Plugin personnalisé pour modifier les URLs des images
        md.renderer.rules.image = (tokens, idx, options, env, self) => {
            const token = tokens[idx];
            const src = token.attrGet('src');

            // Modifiez l'URL ici pour qu'elle soit absolue
            const newSrc = `/content/doc/${src}`;
            token.attrSet('src', newSrc);

            // Ajouter une classe CSS pour contrôler la taille
            token.attrSet('class', 'markdown-image');
            token.attrSet('style', 'max-width: 600px; height: auto;');

            // Rendre l'image avec l'URL modifiée
            return self.renderToken(tokens, idx, options);
        };



        this.renderedMarkdown = md.render(markdownContent);
    }
}
</script>

<style scoped>
.markdown-image {
    max-width: 600px;
    /* Limite la largeur maximale à 300 pixels */
    height: auto;
    /* Maintient le ratio de l'image */
}
</style>
